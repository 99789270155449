import Vue, { PropType } from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import BaseIcon from '../Icon/BaseIcon';
import { IconType } from '../Icon/Icon.types';

import { ButtonIconIconSize, ButtonIconIconPosition } from './ButtonIcon.types';

export default Vue.extend({
  name: 'SldsButtonIconIcon',
  functional: true,
  props: {
    size: {
      type: String as PropType<ButtonIconIconSize>,
      default: undefined,
      validator: (val) => Object.values(ButtonIconIconSize).includes(val),
    },
    hint: {
      type: Boolean,
      default: false,
    },
    inverseHint: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String as PropType<ButtonIconIconPosition>,
      default: undefined,
      validator: (val) => Object.values(ButtonIconIconPosition).includes(val),
    },
  },
  render(h, { props, data }) {
    return h(
      BaseIcon,
      mergeData(data, {
        props: {
          type: IconType.Utility,
        },
        class: {
          [`slds-button__icon_${props.size}`]: props.size !== undefined,
          'slds-button__icon_hint': props.hint,
          'slds-button__icon_inverse-hint': props.inverseHint,
          [`slds-button__icon_${props.position}`]: props.position !== undefined,
        },
        staticClass: 'slds-button__icon',
      })
    );
  },
});

<template>
  <li class="slds-nav-vertical__item" :class="{ 'slds-is-active': isActive }">
    <span
      class="slds-nav-vertical__action slds-text-color_weak"
      :style="interactive ? {} : { 'pointer-events': 'none' }"
    >
      <slot />
      <span v-if="badge" class="slds-badge slds-col_bump-left">
        <span class="slds-assistive-text">:</span>{{ badge }}
        <span class="slds-assistive-text">{{ badgeTitle }}</span>
      </span>
    </span>
  </li>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    active: { type: Boolean, default: false },
    badge: { type: [String, Number] },
    badgeTitle: { type: String, default: 'New Items' },
    interactive: { type: Boolean, default: false },
  },
  data() {
    return { isActive: this.active };
  },
});
</script>

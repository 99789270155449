import { ref, computed, watch } from '@vue/composition-api';

import {
  ProvisioningState,
  useListBranchesForDashboardQuery,
} from '@/graphql/types';
import { extractNode } from '@/util';

export default function useBranchesForDashboard(pollInterval = 2000) {
  const { result, onResult, error, onError, loading } =
    useListBranchesForDashboardQuery({
      errorPolicy: 'all',
      pollInterval,
    });
  const lastUpdateTime = ref<Date | null>(null);

  const branches = computed(
    () => result.value?.branches.edges.map(extractNode) ?? []
  );

  const defaultBranch = computed(
    () =>
      branches.value.find(
        (branch) => branch.provisioningState === ProvisioningState.SUCCEEDED
      ) ?? null
  );

  onResult(() => {
    lastUpdateTime.value = new Date();
  });

  const slowBranches = computed(() =>
    branches.value.filter((branch) => branch.isSlow)
  );

  const errorMessage = ref('');

  watch(error, (err) => {
    if (err === null) {
      errorMessage.value = '';
      return;
    }
    const seen = new Set();
    for (const e of err.graphQLErrors) {
      seen.add(e.message);
    }
    errorMessage.value = [...seen].join(',');
  });

  onError(() => {
    // errors are already handled by the watcher
  });

  return {
    branches,
    defaultBranch,
    lastUpdateTime,
    slowBranches,
    loading,
    errorMessage,
  };
}

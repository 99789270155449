<!--
Example:

<slds-dropdown-trigger>
  <slds-button
    variant="icon"
    icon-variant="border"
    icon-name="down"
    aria-haspopup="true"
    title="Show More"
  >
  </slds-button>

  <slds-dropdown class="slds-dropdown_right slds-dropdown_small">
    <ul class="slds-dropdown__list slds-text-body_small" role="menu">
      <li class="slds-dropdown__item" role="presentation">
        <router-link :to="{name: 'tree', params: {branch: branch['branch_name']}}" role="menuitem" tabindex="-1">
          <span class="slds-truncate">Files</span>
        </router-link>
      </li>
    </ul>
  </slds-dropdown>
</slds-dropdown-trigger>
-->
<template>
  <div
    v-click-outside="close"
    class="slds-dropdown-trigger slds-dropdown-trigger_click"
    :class="[{ 'slds-is-open': isShow }]"
    @click.stop.prevent="toggle"
  >
    <slot />
  </div>
</template>

<script>
import { directive as clickOutside } from 'v-click-outside';

export default {
  name: 'SldsDropdownTrigger',
  directives: {
    clickOutside,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      isShow: this.show,
    };
  },
  watch: {
    show: function (val) {
      this.isShow = val;
    },
  },
  methods: {
    open() {
      if (!this.isShow) {
        this.isShow = true;
        this.$emit('open');
      }
    },
    close() {
      if (this.isShow) {
        this.isShow = false;
        this.$emit('close');
      }
    },
    toggle() {
      if (this.isShow) {
        this.close();
      } else {
        this.open();
      }
    },
  },
};
</script>

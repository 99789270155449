var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOpen)?_c('portal',{attrs:{"to":"slds-modal"}},[_c('section',{ref:"element",class:[
      'slds-modal',
      'slds-fade-in-open',
      ( _obj = {}, _obj[("slds-modal_" + _vm.size)] = _vm.size, _obj ),
      { 'slds-modal_prompt': _vm.isPrompt },
      _vm.className ],attrs:{"aria-describedby":(_vm.id + "-modal-content"),"aria-label":_vm.assistiveText.dialogLabel,"aria-labelledby":_vm.dialogLabelledBy,"aria-modal":true,"role":"dialog","tabindex":"-1"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal.apply(null, arguments)}}},[_c('div',{class:['slds-modal__container', _vm.containerClassName],style:(_vm.modalStyle),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal.apply(null, arguments)}}},[_c('header',{class:[
          'slds-modal__header',
          ( _obj$1 = {
            'slds-modal__header_empty': _vm.headerEmpty
          }, _obj$1[("slds-theme_" + _vm.prompt)] = _vm.isPrompt, _obj$1['slds-theme_alert-texture'] =  _vm.isPrompt, _obj$1 ),
          _vm.headerClassName ]},[_c('slds-button',{staticClass:"slds-modal__close",attrs:{"variant":"icon","icon-name":"close","icon-size":"large","inverse":"","title":_vm.assistiveText.closeButton,"assistive-text":_vm.assistiveText.closeButton},on:{"click":_vm.closeModal}}),(_vm.$slots.header)?[_vm._t("header")]:[_c('div',[_vm._t("toast"),_c('h2',{class:{
                'slds-text-heading_small': _vm.isPrompt,
                'slds-text-heading_medium': !_vm.isPrompt,
              },attrs:{"id":(_vm.id + "-heading")}},[_vm._v(" "+_vm._s(_vm.heading || _vm.title)+" ")]),(_vm.tagline)?_c('p',{staticClass:"slds-m-top_x-small"},[_vm._v(_vm._s(_vm.tagline))]):_vm._e()],2)]],2),_c('div',{class:['slds-modal__content', _vm.contentClassName],style:(_vm.contentStyle),attrs:{"id":(_vm.id + "-modal-content")}},[_vm._t("default")],2),(_vm.$slots.footer)?_c('footer',{class:[
          _vm.footerClassName,
          'slds-modal__footer',
          {
            'slds-modal__footer_directional': _vm.directional,
            'slds-theme_default': _vm.isPrompt,
          } ]},[_vm._t("footer")],2):_vm._e()])]),_c('div',{staticClass:"slds-backdrop slds-backdrop_open"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
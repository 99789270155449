<template>
  <button
    class="slds-button slds-button_neutral slds-button_stateful"
    aria-live="assertive"
    aria-pressed="true"
    :class="classes"
    @focus="focused = true"
    @blur="focused = false"
    @click="toggle"
  >
    <span class="slds-text-not-selected"
      ><SldsButtonIconIcon name="check" size="small" position="left" />Mark
      complete</span
    >
    <span class="slds-text-selected"
      ><SldsButtonIconIcon
        name="check"
        size="small"
        position="left"
      />Complete</span
    >
    <span class="slds-text-selected-focus"
      ><SldsButtonIconIcon name="check" size="small" position="left" />Mark
      uncomplete</span
    >
  </button>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from '@vue/composition-api';

import { SldsButtonIconIcon } from '@ui';

export default defineComponent({
  name: 'AppButtonComplete',
  components: {
    SldsButtonIconIcon,
  },
  props: {
    complete: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    toggle: (complete: boolean) => typeof complete === 'boolean',
  },
  setup(props, { emit }) {
    const focused = ref(false);
    const classes = computed(() => ({
      'slds-not-selected': props.complete === false,
      'slds-is-selected': props.complete,
      'slds-is-selected-focus': props.complete && focused.value,
    }));
    function toggle() {
      emit('toggle', !props.complete);
    }
    return { focused, classes, toggle };
  },
});
</script>

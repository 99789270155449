<!--
Example:

<slds-dropdown-trigger>
  <slds-button
    variant="icon"
    icon-variant="border"
    icon-name="down"
    aria-haspopup="true"
    title="Show More"
  >
  </slds-button>

  <slds-dropdown class="slds-dropdown_right slds-dropdown_small">
    <ul class="slds-dropdown__list slds-text-body_small" role="menu">
      <li class="slds-dropdown__item" role="presentation">
        <router-link :to="{name: 'tree', params: {branch: branch['branchName']}}" role="menuitem" tabindex="-1">
          <span class="slds-truncate">Files</span>
        </router-link>
      </li>
    </ul>
  </slds-dropdown>
</slds-dropdown-trigger>
-->
<template>
  <div class="slds-dropdown">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SldsDropdown',
};
</script>

<template>
  <div>
    <select :id="id" v-model="model" class="app-select-page-size">
      <option :value="10">10</option>
      <option :value="25">25</option>
      <option :value="50">50</option>
      <option :value="75">75</option>
    </select>
    <label :for="id"> per page </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { useVModel } from '@vueuse/core';

import { useRandomId } from '@/composables';

export default defineComponent({
  name: 'AppSelectPageSize',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = useVModel(props, 'value', emit, { eventName: 'input' });
    const { id } = useRandomId();
    return { model, id };
  },
});
</script>

<style lang="scss">
@use '../../../node_modules/@salesforce-ux/design-system/design-tokens/dist/components.default'
  as components;
@use '../../style/variables';

.app-select-page-size {
  width: 32px;
  background-color: transparent;
  border-width: 0;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path fill="rgb(24, 24, 24)" d="M480-360 280-559h400L480-360Z"/></svg>');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position-x: 12px;
  background-position-y: -2px;
}
</style>

<template>
  <GlobalNavigation id="nav">
    <GlobalNavigationItem :to="{ name: 'branches' }" title="Dashboard" />
    <template v-if="defaultBranch !== null">
      <GlobalNavigationItem
        :to="{
          name: 'tree',
          params: { branch: defaultBranch.branchName },
        }"
        title="Files"
      />
      <GlobalNavigationItem
        :to="{ name: 'activity' }"
        title="Activity"
        class="slds-show_medium"
      />
      <GlobalNavigationItem
        :to="{
          name: 'commits',
          params: { branch: defaultBranch.branchName },
        }"
        title="History"
        class="slds-show_medium"
      />
      <GlobalNavigationItem
        :to="{ name: 'compare' }"
        title="Compare"
        class="slds-show_medium"
      />
      <GlobalNavigationItem
        :to="{ name: 'deployments.open' }"
        title="Deployments"
      />
    </template>
  </GlobalNavigation>
</template>

<script lang="ts">
import BranchesConsumer from '@/module-next/branches/BranchesConsumer.vue';

import GlobalNavigation from './GlobalNavigation.vue';
import GlobalNavigationItem from './GlobalNavigationItem.vue';

export default BranchesConsumer.extend({
  name: 'RepoNavigation',
  components: {
    GlobalNavigation,
    GlobalNavigationItem,
  },
});
</script>

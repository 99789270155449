<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'AppSidebarSection',
  functional: true,
  props: {
    title: { type: String, required: true },
  },
  render(h, { props, data, children }) {
    return h(
      'div',
      mergeData(data, { staticClass: 'slds-nav-vertical__section' }),
      [
        h('h2', { staticClass: 'slds-nav-vertical__title' }, props.title),
        h('ul', undefined, children),
      ]
    );
  },
});
</script>

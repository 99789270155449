<template>
  <portal v-if="isOpen" to="slds-modal">
    <section
      ref="element"
      :aria-describedby="`${id}-modal-content`"
      :aria-label="assistiveText.dialogLabel"
      :aria-labelledby="dialogLabelledBy"
      :aria-modal="true"
      :class="[
        'slds-modal',
        'slds-fade-in-open',
        { [`slds-modal_${size}`]: size },
        { 'slds-modal_prompt': isPrompt },
        className,
      ]"
      role="dialog"
      tabindex="-1"
      @click.self="closeModal"
      @keyup.esc="closeModal"
    >
      <div
        :class="['slds-modal__container', containerClassName]"
        :style="modalStyle"
        @click.self="closeModal"
      >
        <header
          :class="[
            'slds-modal__header',
            {
              'slds-modal__header_empty': headerEmpty,
              [`slds-theme_${prompt}`]: isPrompt,
              'slds-theme_alert-texture': isPrompt,
            },
            headerClassName,
          ]"
        >
          <slds-button
            variant="icon"
            icon-name="close"
            icon-size="large"
            inverse
            :title="assistiveText.closeButton"
            :assistive-text="assistiveText.closeButton"
            class="slds-modal__close"
            @click="closeModal"
          />

          <template v-if="$slots.header">
            <slot name="header" />
          </template>
          <template v-else>
            <div>
              <slot name="toast" />

              <h2
                :id="`${id}-heading`"
                :class="{
                  'slds-text-heading_small': isPrompt,
                  'slds-text-heading_medium': !isPrompt,
                }"
              >
                {{ heading || title }}
              </h2>

              <p v-if="tagline" class="slds-m-top_x-small">{{ tagline }}</p>
            </div>
          </template>
        </header>

        <div
          :id="`${id}-modal-content`"
          :class="['slds-modal__content', contentClassName]"
          :style="contentStyle"
        >
          <slot />
        </div>

        <footer
          v-if="$slots.footer"
          :class="[
            footerClassName,
            'slds-modal__footer',
            {
              'slds-modal__footer_directional': directional,
              'slds-theme_default': isPrompt,
            },
          ]"
        >
          <slot name="footer" />
        </footer>
      </div>
    </section>
    <div class="slds-backdrop slds-backdrop_open"></div>
  </portal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import type { WithRefs } from 'vue-typed-refs';

import { generateId } from '@/util';

type Refs = {
  element: HTMLElement;
};

const documentDefined = typeof document !== 'undefined';
const windowDefined = typeof window !== 'undefined';

/**
 * The Modal component is used for the Lightning Design System Modal and
 * Notification > Prompt components. The Modal opens from a state change
 * outside of the component itself (pass this state to the <code>isOpen</code>
 * prop).
 *
 * For more details on the Prompt markup, please review the
 * <a href="http://www.lightningdesignsystem.com/components/notifications#prompt">Notifications > Prompt</a>.
 *
 * By default, `Modal` will add `aria-hidden=true` to the `body` tag, but this
 * disables some assistive technologies.
 *
 * This component uses a portal-vue (a disconnected React subtree mount) to
 * create a modal as a child of `body`. You need to place a `<portal-target>`
 * at the end of the page.
 *
 * ```
 * <body>
 *   <!-- Main content here -->
 *
 *   <portal-target name="slds-modal" transition />
 * </body>
 * ```
 *
 * @slot default - Modal content
 * @slot header - Allows for a custom modal header that does not scroll with modal content. If this is defined, `heading` and `tagline` will be ignored. The close button will still be present.
 * @slot footer - Accepts a node or array of nodes that are typically a `Button` or `ProgressIndicator`. If an array, the nodes render on the right side first but are then floated left and right if <code>directional</code> prop is `true`.
 *
 * @slot tagline - Content underneath the heading in the modal header.
 * @slot title - Content underneath the title in the modal header.
 * @slot heading - Text heading at the top of a modal.
 * @slot toast - Allows adding additional notifications within the modal.
 *
 * @event onRequestClose Callback to fire with Modal is dismissed
 */
export default (Vue as WithRefs<Refs>).extend({
  name: 'SldsModal',
  props: {
    /**
     * Vertical alignment of Modal.
     */
    align: {
      type: String,
      validator(val: string) {
        return ['top', 'center'].includes(val);
      },
    },
    /**
     * Boolean indicating if the appElement should be hidden.
     */
    ariaHideApp: Boolean,
    /**
     * **Assistive text for accessibility.**
     * This object is merged with the default props object on every render.
     * * `dialogLabel`: This is a visually hidden label for the dialog. If not provided, `heading` is used.
     * * `dialogLabelledBy`: This describes which node labels the dialog. If not provided and dialogLabel is unavailable, `id` is used.
     * * `closeButton`: This is a visually hidden label for the close button.
     */
    assistiveText: {
      type: Object as PropType<{
        dialogLabel?: string;
        dialogLabelledBy?: string;
        closeButton: string;
      }>,
      default() {
        return {
          dialogLabel: undefined,
          dialogLabelledBy: undefined,
          closeButton: 'Close',
        };
      },
    },
    /**
     * Custom CSS classes for the modal `section` node classed `.slds-modal` and the parent of `.slds-modal__container`. Uses `classNames` [API](https://github.com/JedWatson/classnames).
     */
    className: {},
    /**
     * Custom CSS classes for the modal's container. This is the child element of `.slds-modal` with class `.slds-modal__container`. Uses `classNames` [API](https://github.com/JedWatson/classnames).
     */
    containerClassName: {},
    /**
     * Custom CSS classes for the modal's body. This is the element that has overflow rules and should be used to set a static height if desired. Use `classNames` [API](https://github.com/JedWatson/classnames).
     */
    contentClassName: {
      type: [Object, Array, String],
      default: 'slds-p-around_medium slds-is-relative',
    },
    /**
     * Custom CSS classes for the modal's footer.
     */
    footerClassName: {
      type: [Object, Array, String],
    },
    /**
     * Custom styles for the modal's body. This is the element that has overflow rules and should be used to set a static height if desired.
     */
    contentStyle: Object,
    /**
     * If true, modal footer buttons render left and right. An example use case would be for "back" and "next" buttons.
     */
    directional: Boolean,
    /**
     * Adds CSS classes to the container surrounding the modal header and the close button. Use `classNames` [API](https://github.com/JedWatson/classnames).
     */
    headerClassName: {},
    /**
     * Unique identifier for the modal. The id is automatically generated if not provided
     */
    id: {
      type: String,
      default() {
        return generateId();
      },
    },
    /**
     * Forces the modal to be open or closed. Use two-way binding:
     *   <slds-modal :open.sync="myProperty" />
     */
    open: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },
    /**
     * Styles the modal as a prompt.
     */
    prompt: {
      type: String as PropType<string | undefined>,
      validator(val: string) {
        return [
          'success',
          'warning',
          'error',
          'wrench',
          'offline',
          'info',
        ].includes(val);
      },
    },
    /**
     * Specifies the modal's width. May be deprecated in favor of `width` in the future.
     */
    size: {
      type: String,
      validator(val: string) {
        return ['small', 'medium', 'large'].includes(val);
      },
    },
    /**
     * Content underneath the heading in the modal header.
     */
    tagline: String,
    /**
     * Content underneath the title in the modal header.
     */
    title: String,
    /**
     * Text heading at the top of a modal.
     */
    heading: String,
  },
  data() {
    return {
      isOpen: this.open,
      returnFocusTo: null as HTMLElement | null,
    };
  },
  computed: {
    isPrompt(): boolean {
      return this.prompt !== undefined;
    },
    dialogLabelledBy(): string | undefined {
      if (this.assistiveText.dialogLabelledBy) {
        return this.assistiveText.dialogLabelledBy;
      }
      if (!this.assistiveText.dialogLabel && (this.heading || this.title)) {
        return `${this.id}-heading`;
      }
      return undefined;
    },
    modalStyle(): Partial<CSSStyleDeclaration> {
      return this.align === 'top' ? { justifyContent: 'flex-start' } : {};
    },
    headerEmpty(): boolean {
      return (
        !this.$slots.header && !(this.heading || this.title) && !this.tagline
      );
    },
  },
  watch: {
    open() {
      this.isOpen = this.open;
    },
    isOpen(value: boolean) {
      if (value) {
        this.captureFocus();
        this.updateBodyScroll();
      } else {
        this.returnFocus();
        this.clearBodyScroll();
      }
    },
  },
  methods: {
    /**
     * Programatically opens the modal dialog.
     */
    openModal() {
      if (this.open !== undefined) {
        this.$emit('update:open', true);
        this.$emit('show');
      } else {
        this.isOpen = true;
      }
    },

    /**
     * Programatically closes the modal dialog.
     */
    closeModal() {
      if (this.open !== undefined) {
        this.$emit('update:open', false);
        this.$emit('hide');
      } else {
        this.isOpen = false;
      }
    },

    // Private /////////////////////////////////////////////////////////////////

    captureFocus() {
      this.returnFocusTo = documentDefined
        ? (document.activeElement as HTMLElement)
        : null;

      this.$nextTick(() => {
        // programatically moves focus to section[tabindex="-1] so that
        // the next tab lands on the close button. this also allows
        // keydown.ESC to be captured naturally.
        this.$refs.element.focus();
      });
    },

    returnFocus() {
      if (this.returnFocusTo && this.returnFocusTo.focus) {
        this.returnFocusTo.focus();
      }
    },

    updateBodyScroll() {
      if (windowDefined && documentDefined && document.body) {
        if (this.isOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'inherit';
        }
      }
    },

    clearBodyScroll() {
      if (windowDefined && documentDefined && document.body) {
        document.body.style.overflow = 'inherit';
      }
    },
  },
});
</script>

<template>
  <RouterLink
    v-slot="{ href, isActive, navigate }"
    v-bind="$attrs"
    custom
    v-on="$listeners"
  >
    <li :class="{ 'slds-is-active': isActive }" class="slds-nav-vertical__item">
      <a
        :href="href"
        :aria-current="isActive ? 'true' : undefined"
        class="slds-nav-vertical__action"
        @click="navigate"
      >
        <slot />
      </a>
    </li>
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AppSidebarRouterLink',
  inheritAttrs: false,
});
</script>

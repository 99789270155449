<script lang="ts">
import Vue from 'vue';

import {
  ProvisioningState,
  BranchFragmentFragment,
  GetBranchesQuery,
} from '@/graphql/types';

import { extractNode } from '@/util';

/* Will be `useBranches` in Vue 3 */
export default Vue.extend({
  data() {
    return {
      branches: [] as BranchFragmentFragment[],
    };
  },
  computed: {
    defaultBranch(): BranchFragmentFragment | null {
      return (
        this.branches.find(
          (branch) => branch.provisioningState === ProvisioningState.SUCCEEDED
        ) || null
      );
    },
  },
  apollo: {
    branches: {
      query: require('@/module-next/branches/schema/getBranches.graphql'),
      update(data: GetBranchesQuery): BranchFragmentFragment[] {
        return data.branches.edges.map(extractNode);
      },
    },
  },
});
</script>

import type { Route, RouteConfig } from 'vue-router';
import Router from 'vue-router';
import { endOfDay, parseISO } from 'date-fns';

import { DeploymentType, DeploymentsFilter } from '@/graphql/types';

import RepoView from './pages/RepoView.vue';
import RepoNavigation from './components/RepoNavigation.vue';
import NotImplementedView from './pages/NotImplementedView.vue';
import type { DeploymentListPageProps } from './module-next/deployments/DeploymentListPage.types';
import { DeploymentTab } from './module/deployments/components/DeploymentSectionTabs/DeploymentSectionTabs.types';

function deploymentFilterRoute(
  name: string,
  suffix: string | undefined,
  filter: DeploymentsFilter
) {
  const route: RouteConfig = {
    path: suffix ? `deployments/${suffix}` : 'deployments',
    name,
    component: () => import('@/module-next/deployments/DeploymentListPage.vue'),
    props: (route) => {
      const props: DeploymentListPageProps = { filter };
      const { label } = route.query;
      if (typeof label === 'string') {
        props.label = label;
      }
      const sourceBranch = route.query['source-branch'];
      if (typeof sourceBranch === 'string') {
        props.sourceBranch = sourceBranch;
      }
      const targetBranch = route.query['target-branch'];
      if (typeof targetBranch === 'string') {
        props.targetBranch = targetBranch;
      }
      const { from, to } = route.query;
      if (typeof from === 'string' && typeof to === 'string') {
        props.range = {
          start: parseISO(from),
          end: endOfDay(parseISO(to)),
        };
      }
      return props;
    },
  };
  return route;
}

function number(to: Route) {
  return { number: +to.params.number };
}

const router = new Router({
  base: '',
  mode: 'history',
  routes: [
    {
      path: '/',
      components: {
        default: RepoView,
        navigation: RepoNavigation,
      },
      children: [
        {
          path: '',
          redirect: { name: 'branches' },
        },
        {
          path: 'branches',
          name: 'branches',
          component: () => import('./pages/BranchesView.vue'),
        },
        {
          path: 'branches/create',
          name: 'branches.create',
          component: () =>
            import('@/module-next/branches/BranchCreatePage.vue'),
          props: (to) => {
            const { swimlane } = to.query;
            if (typeof swimlane === 'string') {
              return { swimlane };
            }
            return null;
          },
        },
        {
          path: 'branches/:branch_name/requests/:id',
          name: 'branchsync-request',
          component: () =>
            import('@/module/branchsync/pages/BranchsynRequestView.vue'),
          props: (to) => {
            return {
              branchName: to.params.branch_name,
              identifier: to.params.id,
            };
          },
        },
        {
          path: 'branches/:branch_name/syncs',
          name: 'branchsyncs',
          component: () =>
            import('@/module/branchsync/pages/BranchsyncsView.vue'),
          props: (to) => {
            return {
              branchName: to.params.branch_name,
              type: to.query.type || undefined,
            };
          },
          children: [
            {
              path: ':uuid',
              name: 'branchsync',
              component: () =>
                import(
                  '@/module/branchsync/pages/BranchsyncView/BranchsyncView.vue'
                ),
              props: (to) => {
                return { uuid: to.params.uuid };
              },
            },
          ],
        },
        {
          path: `deployments/:number(\\d+)/:tab(${Object.values(
            DeploymentTab
          ).join('|')})?`,
          name: 'deployment',
          component: () =>
            import('./module/deployments/DeploymentViewPage.vue'),
          props: (to) => {
            return {
              number: +to.params.number,
              tab: to.params.tab,
            };
          },
        },
        {
          path: 'deployments/:deployment(\\d+)/merge/:id',
          name: 'deployment.merge',
          component: () =>
            import(
              './module-next/deployments/conflicts/DeploymentConflictsPage.vue'
            ),
          props: (to) => {
            return {
              deployment: +to.params.deployment,
              id: to.params.id,
            };
          },
        },
        deploymentFilterRoute(
          'deployments.open',
          undefined,
          DeploymentsFilter.OPEN
        ),
        deploymentFilterRoute(
          'deployments.listSubmittedByMe',
          'me',
          DeploymentsFilter.SUBMITTED_BY_ME
        ),
        deploymentFilterRoute(
          'deployments.listAssignedToMe',
          'assigned',
          DeploymentsFilter.ASSIGNED_TO_ME
        ),
        deploymentFilterRoute(
          'deployments.needsReview',
          'review',
          DeploymentsFilter.NEEDS_REVIEW
        ),
        deploymentFilterRoute(
          'deployments.listDraft',
          'draft',
          DeploymentsFilter.DRAFT
        ),
        deploymentFilterRoute(
          'deployments.listPackaging',
          'packaging',
          DeploymentsFilter.PACKAGING
        ),
        deploymentFilterRoute(
          'deployments.listValidating',
          'validating',
          DeploymentsFilter.VALIDATING
        ),
        deploymentFilterRoute(
          'deployments.listReadyToDeploy',
          'validated',
          DeploymentsFilter.READY_TO_DEPLOY
        ),
        deploymentFilterRoute(
          'deployments.listDeployed',
          'deployed',
          DeploymentsFilter.DEPLOYED
        ),
        deploymentFilterRoute(
          'deployments.listErrors',
          'errors',
          DeploymentsFilter.ERROR
        ),
        deploymentFilterRoute(
          'deployments.checklists',
          'checklists',
          DeploymentsFilter.CHECKLIST
        ),
        deploymentFilterRoute(
          'deployments.bulk',
          'releases',
          DeploymentsFilter.RELEASE
        ),
        deploymentFilterRoute(
          'deployments.permissions',
          'permissions',
          DeploymentsFilter.PERMISSIONS
        ),
        deploymentFilterRoute(
          'deployments.stories',
          'stories',
          DeploymentsFilter.STORY
        ),
        deploymentFilterRoute(
          'deployments.listDeleted',
          'deleted',
          DeploymentsFilter.DELETED
        ),
        deploymentFilterRoute(
          'deployments.listComplete',
          'complete',
          DeploymentsFilter.MARKED_AS_COMPLETE
        ),
        deploymentFilterRoute(
          'deployments.listAll',
          'all',
          DeploymentsFilter.ALL
        ),
        deploymentFilterRoute(
          'deployments.conflicts',
          'conflicts',
          DeploymentsFilter.HAS_MERGE_CONFLICTS
        ),
        deploymentFilterRoute(
          'deployments.merge',
          'merge',
          DeploymentsFilter.NEEDS_MERGE
        ),
        // TODO(Andrew): DRY
        {
          path: 'deployments/create/:source([^@]+)...:target([^/]+)',
          name: 'deployments.create',
          component: () =>
            import('@/module-next/deployments/DeploymentCreatePageWrapper.vue'),
          props: (route) => ({
            source: route.params.source,
            target: route.params.target,
            type: route.query.type || DeploymentType.STORY,
          }),
        },
        {
          path: 'deployments/edit/:number',
          name: 'deployments.edit',
          component: () =>
            import('@/module-next/deployments/DeploymentEditPage.vue'),
          props: number,
        },
        // for old deployment requests without a number
        {
          path: 'deployments/draft/:id',
          name: 'deployments.edit.legacy',
          component: () =>
            import('@/module-next/deployments/DeploymentEditByIdPage.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
        },
        {
          path: 'history/:branch',
          name: 'commits',
          component: () => import('./module/history/ListCommitsPage.vue'),
          props: true,
        },

        {
          path: 'settings',
          name: 'settings',
          component: () =>
            import('@/module-next/settings/SettingsBasePage.vue'),
          children: [
            {
              path: 'branches',
              name: 'settings.branches',
              component: () =>
                import('@/module-next/settings/SettingsBranchesPage.vue'),
            },
            {
              path: 'branches/:branch_name',
              name: 'settings.branches.branch',
              component: () =>
                import('@/module/branch/pages/BranchSettingsPage.vue'),
              props: (to) => ({ branchName: to.params.branch_name }),
            },
            {
              path: 'features/nextgen-sync',
              name: 'settings.features.nextgen-sync',
              component: () =>
                import('@/module-next/settings/SettingsNextgenSyncPage.vue'),
            },
            {
              path: 'branch-rules/create',
              name: 'settings.branchRules.create',
              component: () =>
                import(
                  '@/module-next/settings/SettingsBranchRulesCreatePage.vue'
                ),
            },
            {
              path: 'branch-rules/edit/:id',
              name: 'settings.branchRules.edit',
              props: (to) => ({
                id: to.params.id,
              }),
              component: () =>
                import(
                  '@/module-next/settings/SettingsBranchRulesEditPage.vue'
                ),
            },
            {
              path: 'reports',
              name: 'settings.reports',
              component: () =>
                import('@/module-next/settings/SettingsReportsPage.vue'),
            },
            {
              path: 'metadata/rules/:type?',
              name: 'settings.metadata.rules',
              component: () =>
                import('@/module/metadata/views/MetadataSyncRulesView.vue'),
              props: (to) => ({ type: to.params.type }),
            },
            {
              path: 'metadata/configuration',
              name: 'settings.metadata.configuration',
              component: () =>
                import(
                  '@/module/metadata/views/MetadataConfigurationView/MetadataConfigurationView.vue'
                ),
            },
            {
              path: 'templates/description',
              name: 'settings.templates.description',
              component: () =>
                import(
                  '@/module-next/settings/SettingsDeploymentTemplatesPage.vue'
                ),
            },
            {
              path: 'templates/description/create',
              name: 'settings.templates.description.create',
              component: () =>
                import(
                  '@/module-next/settings/SettingsDeploymentTemplatesCreatePage.vue'
                ),
            },
            {
              path: 'templates/description/update/:id',
              name: 'settings.templates.description.update',
              props: true,
              component: () =>
                import(
                  '@/module-next/settings/SettingsDeploymentTemplatesUpdatePage.vue'
                ),
            },
            {
              path: 'templates/permissions',
              name: 'settings.templates.permissions',
              component: () =>
                import(
                  '@/module/permissions/pages/PermissionsSelectionTemplatesPage/PermissionsSelectionTemplatesPage.vue'
                ),
            },
            {
              path: 'templates/permissions/create',
              name: 'settings.templates.permissions.create',
              component: () =>
                import(
                  '@/module/permissions/pages/PermissionsSelectionTemplateCreatePage/PermissionsSelectionTemplateCreatePage.vue'
                ),
            },
            {
              path: 'templates/permissions/update/:id',
              name: 'settings.templates.permissions.update',
              props: true,
              component: () =>
                import(
                  '@/module/permissions/pages/PermissionsSelectionTemplateUpdatePage/PermissionsSelectionTemplateUpdatePage.vue'
                ),
            },
            {
              path: 'git/:branch?',
              name: 'settings.git',
              component: () =>
                import('@/module-next/settings/SettingsGitPage.vue'),
              props: true,
            },
            {
              path: 'integrations',
              name: 'settings.integrations',
              component: () =>
                import('@/module-next/settings/SettingsIntegrationsPage.vue'),
            },
            {
              path: 'swimlanes',
              name: 'settings.swimlanes',
              component: () =>
                import('@/module-next/settings/SettingsSwimlanesPage.vue'),
            },
            {
              path: 'users',
              name: 'settings.users',
              component: () =>
                import('@/module-next/settings/SettingsUsersPage.vue'),
            },
            {
              path: 'roles',
              name: 'settings.roles',
              component: () =>
                import('@/module-next/settings/SettingsRolesPage.vue'),
            },
            {
              path: 'users/:id',
              name: 'settings.user',
              component: () =>
                import('@/module-next/settings/SettingsUserPage.vue'),
              props: true,
            },
            {
              path: 'permissions',
              name: 'settings.permissions',
              component: () =>
                import('@/module-next/settings/SettingsPermissionsPage.vue'),
            },
            {
              path: 'webhooks',
              name: 'settings.webhooks',
              component: () =>
                import('@/module-next/settings/SettingsWebhooksPageNext.vue'),
            },
            {
              path: 'legacy-webhooks',
              name: 'settings.legacy-webhooks',
              component: () =>
                import('@/module-next/settings/SettingsWebhooksPage.vue'),
            },
            {
              path: 'notifications',
              name: 'settings.notifications',
              component: () =>
                import('@/module-next/settings/SettingsNotificationsPage.vue'),
            },
            {
              path: 'sso',
              name: 'settings.sso',
              component: () => import('@/module/sso/SsoSettingsPage.vue'),
            },
          ],
        },

        // GitLab Proxies

        {
          path: 'tree/:branch/:path*',
          name: 'tree',
          component: () => import('./pages/ProxyViewTree.vue'),
          props: true,
        },
        {
          path: 'activity',
          name: 'activity',
          component: () => import('./pages/Activity/ActivityPage.vue'),
        },
        {
          path: 'commit/:commit',
          name: 'commit',
          component: () => import('./pages/ProxyViewCommit.vue'),
          props: true,
        },
        {
          path: 'blob/:branch_name/:path*',
          name: 'blob',
          component: NotImplementedView,
        },

        {
          path: 'compare',
          name: 'compare',
          component: () => import('./pages/EditCompareView.vue'),
          props: (route) => ({
            ...route.params,
            type: route.query.type,
          }),
        },
        {
          path: 'profile',
          name: 'profile',
          component: () =>
            import('@/module/profile/pages/ProfilePage/ProfilePage.vue'),
        },
        { path: '', redirect: '/' },
        {
          path: '404',
          name: '404',
          component: () => import('@/pages/404.vue'),
        },

        {
          path: '*',
          redirect: (to) => {
            const OLD_PATH_REGEX = /\/manage\/?(.+?\/)?/;
            if (
              OLD_PATH_REGEX.test(to.path) &&
              (to.meta === undefined || to.meta.redirected !== true)
            ) {
              const path = to.path.replace(OLD_PATH_REGEX, '');
              const meta = to.meta ?? {};
              meta.redirected = true;
              to.meta = meta;
              return `/${path}`;
            }
            return { name: '404' };
          },
        },
      ],
    },
  ],
});

export default router;

import { computed } from '@vue/composition-api';

import type { BranchName } from '@/types';
import { useUpdateBookmarksMutation } from '@/graphql/types';

import useProfile from '../useProfile';

export default function useBookmarks() {
  const { profile } = useProfile();
  const bookmarks = computed(
    () => new Set(profile.value.bookmarks.map((branch) => branch.branchName))
  );
  const { mutate } = useUpdateBookmarksMutation();
  async function toggle(branchName: BranchName): Promise<void> {
    const user = profile.value;
    const branch =
      profile.value.bookmarks.find((b) => b.branchName === branchName) ?? null;
    let input: BranchName[] = [];
    if (branch !== null) {
      const s = new Set(bookmarks.value);
      s.delete(branchName);
      input = [...s];
    } else {
      input = [...bookmarks.value, branchName];
    }
    await mutate(
      { id: user.id, bookmarks: input },
      {
        optimisticResponse: {
          __typename: 'Mutation',
          updateUser: {
            __typename: 'UpdateUserPayload',
            user: {
              ...user,
              bookmarks:
                branch === null
                  ? [
                      ...user.bookmarks,
                      { __typename: 'Branch', id: 'ID', branchName },
                    ]
                  : user.bookmarks.filter(
                      (b) => b.branchName !== branch.branchName
                    ),
            },
          },
        },
      }
    );
  }

  function isBookmarked(branch: BranchName): boolean {
    return bookmarks.value.has(branch);
  }

  return {
    bookmarks,
    toggle,
    isBookmarked,
  };
}

import { computed } from '@vue/composition-api';

import { ProvisioningState, useGetBranchesQuery } from '@/graphql/types';
import { extractNode } from '@/util';

export default function useBranches() {
  const { result } = useGetBranchesQuery({}, { errorPolicy: 'all' });

  const branches = computed(
    () => result.value?.branches.edges.map(extractNode) ?? []
  );

  const defaultBranch = computed(
    () =>
      branches.value.find(
        (branch) => branch.provisioningState === ProvisioningState.SUCCEEDED
      ) || null
  );

  return {
    branches,
    defaultBranch,
  };
}
